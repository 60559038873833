import { styled } from "@linaria/react"

import { FONT_WEIGHTS } from "src/styles/sizes"

import { TEXT_COLOR } from "./constants"

export const DRAWER_BORDER_COLOR = "#d7dee8"

export const Container = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  background: white;
  width: 336px;
  height: 100%;
  border-left: 1px solid ${DRAWER_BORDER_COLOR};
`

export const Title = styled.div`
  font-family: "Space Grotesk", sans-serif;
  font-weight: ${FONT_WEIGHTS.bold};
  font-size: 14px;
  font-style: normal;
  line-height: 130%;
  letter-spacing: -0.28px;
  color: ${TEXT_COLOR};
`

export const SubTitle = styled(Title)`
  color: #46404f;
`
