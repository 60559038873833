import { styled } from "@linaria/react"
import { Card, Row } from "react-bootstrap"

import { ContentContainer } from "src/styles/components/ContentComponents"
import { BREAKPOINTS } from "src/styles/sizes"
import { BORDER_COLOR, TEXT_COLOR } from "src/features/SupportingMaterialsVault/constants"

export const Container = styled.div`
  height: auto;
`

export const ScrollContainer = styled.div`
  height: calc(100vh - 123px);
  overflow: auto;
`

export const ItemsListContainer = styled(ContentContainer)`
  @media (min-width: ${BREAKPOINTS.medium}) {
    max-width: 1000px;
  }
`

export const ItemCardContainer = styled(Card)`
  border-radius: 8px;
  border: 1px solid ${BORDER_COLOR};
  box-shadow: none;
  padding: 0;
`

export const CardHeaderContainer = styled.div`
  padding: 15px;
  border-bottom: 1px solid ${BORDER_COLOR};
`

export const CardTitleContainer = styled(Row)`
  margin: 0;
  justify-content: space-between;
  color: ${TEXT_COLOR};
  font-size: 14px;
  font-weight: 300;

  .icon-rotated {
    transform: rotate(180deg);
  }
`

export const CardTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.32px;
  padding-left: 7px;
`

export const Badge = styled.span`
  border-radius: 100px;
  background: ${BORDER_COLOR};
  color: #7e90a9;
  padding: 2px 5px;
  font-size: 10px;
  margin-right: 5px;
`

export const AlertContainer = styled.div`
  margin-top: 10px;
  padding-left: 25px;
`

export const DueDateExplanation = styled.div`
  font-size: 12px;
  font-weight: 300;
`
